html,
body {
  width: 100vw;
  height: 100vh;
  /*noinspection CssInvalidPropertyValue*/
  height: -webkit-fill-available;
  font-family: Helvetica;
  overflow: hidden;
}

body {
  user-select: none;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  display: contents;
}

.hidden-on-empty:empty {
  display: none !important;
}

.super-centered {
  display: grid;
  place-items: center;
}

.user-selectable {
  user-select: text;
}

.rbc-overlay {
  background: white;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #f1f1f1;
  font-size: 11px;
}

.rbc-overlay .rbc-overlay-header {
  background: #eaf5ff;
  padding: 10px;
  border-radius: 4px 4px 0px 0px;
  margin: -10px -10px 10px -10px;
}

.rbc-overlay span.booked-time {
  background: #ffffff;
  border: 1px solid #ebedf3;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 3px;
  display: block;
}

.input-type:focus {
  border: 1px solid #1f3cba !important;
}

.rbc-overlay span.available-time {
  padding: 5px;
  background: #f3fbfe;
  border: 1px solid #f3fbfe;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 5px;
  display: block;
}

.rbc-overlay span.meeting-number {
  color: #b5b5c3;
  font-weight: bold;
}

.rbc-overlay span.meeting-number:after {
  contain: '';
  display: block;
  clear: both;
}

.rbc-overlay .availability-slot {
  margin-bottom: 4px;
}

.rbc-overlay .meeting-number .action-btns {
  float: right;
}

.menu-button-with-icon-and-dropdown span:first-child {
  display: inline-block;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

/* iPad Safari Mobile rotated portrait scale fix */
@media only screen and (min-width: 910px) and (orientation: portrait) {
  body {
    -webkit-transform: scale(1);
    -webkit-transform-origin: top left;
    position: absolute;
    left: 0;
    top: 0;
    max-width: 910px;
  }
}

span[data-tag] {
  font-weight: bold;
}
